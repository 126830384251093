// 仪表板接口
import axios from '@/config/httpConfig'
// 获取仪表板条件时间
export function getDashboardConditionTime() {
    return axios.post('/dashboardCondition/getDashboardConditionTime')
}
// 获取用户所属角色下所有用户
export function getUserList() {
    return axios.post('/dashboardCondition/getUserList')
}
// 获取仪表板组件布局
export function getChartsByDashboardId(data) {
    return axios.post('/chart/getChartsByDashboardId', data)
}
// 获取仪表板条件
export function getDashboardConditionByDashboardId(data) {
    return axios.post('/dashboardCondition/getDashboardConditionByDashboardId', data)
}
//重命名仪表板
export function updateDashboard(data) {
    return axios.post('/dashboard/updateDashboard', data)
}
// 添加仪表板条件配置
export function addDashboardCondition(data) {
    return axios.post('/dashboardCondition/addDashboardCondition', data)
}
// 编辑图表
export function updateChart(data) {
    return axios.post('/chart/updateChart', data)
}
//删除仪表板
export function deleteDashboard(data) {
    return axios.post('/dashboard/deleteDashboard', data)
}
//获取预测角色树信息
export function getRoles() {
    return axios.post('/salesFunnel/getRoles')
}
// 批量恢复 发布状态为已归档的知识文章
export function batchRecoverArchived(data) {
    return axios.post('/knowledgeArticleOperate/batchRecoverArchived', data)
}

// 恢复发布状态为已归档的知识文章
export function recoverArchived(data) {
    return axios.post("/knowledgeArticleOperate/recoverArchived", data);
}
// 知识文章批量归档
export function batchArticlePigeonhole(data) {
    return axios.post('/KnowledgeArticle/batchArticlePigeonhole', data)
}

//归档
export function articlePigeonhole(data) {
    return axios.post("/KnowledgeArticle/articlePigeonhole", data);
}

// 批量删除 知识文章
export function batchRemoveArticle(data) {
    return axios.post('/knowledgeArticleOperate/batchRemoveArticle', data)
}
//删除知识文章
export function removeArticle(data) {
    return axios.post("/knowledgeArticleOperate/removeArticle", data);
}
// 获取报表文件夹
export function getFolder(data) {
    return axios.post('/report/getReportFolders', data)
}
// 批量删除发布状态为草稿的知识文章
export function batchRemoveDraft(data) {
    return axios.post('/knowledgeArticleOperate/batchRemoveDraft', data)
}
// 删除发布状态为草稿的知识文章
export function removeDraft(data) {
    return axios.post("/knowledgeArticleOperate/removeDraft", data);
}

// 更改所有人是否展示伙伴云和队列
export function checkQueueAndPartner(data) {
    return axios.post('/objectdetail/checkQueueAndPartner', data)
}

//获取更改所有人页面信息-批量
export function getTransfer(data) {
    return axios.post('/transfer/getTransfer', data)
}

//单条数据转移所有人
export function updateChangeOwner(data) {
    return axios.post('/transfer/updateChangeOwner', data)
}

//批量转移所有人
export function updateTransfer(data) {
    return axios.post('/transfer/updateTransfer', data)
}