<template>
  <div class="content">
    <!--市场活动层次结构 -->
    <el-tree
      class="tree"
      :data="structureData"
      :props="defaultProps"
      @node-expand="handleNodeClick"
      :default-expanded-keys="[recordId]"
      :expand-on-click-node="false"
      node-key="id"
    >
      <div class="tree-box custom-tree-node" slot-scope="{ node, data }">
        <div
          :class="data.permission == 'true' ? 'link-name' : 'no-permission'"
          @click.exact="getLinkDetail(data)"
          @click.ctrl.exact="getLinkDetail(data)"
          @click.meta.exact="getLinkDetail(data)"

        >
          {{ node.label }}
        </div>

        <el-popover
          popper-class="pop_small"
          placement="bottom"
          width="60"
          trigger="click"
        >
          <div>
            <ul>
              <li
                v-for="(item, index) in editBtnList"
                :key="index"
                @click="getOperation(item, data)"
              >
                <span class="cursor-pointer"> {{ item.label }} </span>
              </li>
            </ul>
          </div>
          <div slot="reference" class="edit-icon">
            <svg class="icon" aria-hidden="true">
              <use href="#icon-xiala"></use>
            </svg>
          </div>
        </el-popover>
      </div>
    </el-tree>
  </div>
</template>

<script>
import * as CommonObjApi from "./api";

/**
 * @description: 市场活动层级结构
 * 功能：
 * 1、层级结构树
 * 2、操作按钮
 * 逻辑：
 * 1、根据传入的数据展示结构树
 * 2、根据操作按钮权限对数据进行处理
 */

export default {
  props: {
    // 市场活动层级结构数据
    dataTree: {
      type: Object,
      default: () => {},
    },
    recordId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      structureData: [], //结构树数据
      defaultProps: {
        children: "children",
        label: "label",
      },
      //   操作按钮
      editBtnList: [
        {
          id: "edit",
          label: "编辑",
        },
        {
          id: "delete",
          label: "删除",
        },
      ],
    };
  },
  methods: {
    // 获取当前操作
    // action当前执行的操作
    // data执行的数据
    getOperation(action, value) {
      let operationData = JSON.parse(JSON.stringify(value));
      CommonObjApi.getPermissionById({ id: value.id }).then((res) => {
        if (action.id === "delete") {
          // 非锁定状态且有编辑权限时可删除
          if (res.data.isDelete && !res.data.isLocked) {
            this.$set(operationData, "data", { id: value.id });
            this.$emit("del", operationData);
          } else {
            this.$message({
              showClose: true,
              message: this.$i18n.t("vue_label_notice_nopermission_delete"),
              type: "warning",
            });
            //抱歉，您无权删除该条数据，请联系系统管理员。
          }
        } else if (action.id === "edit") {
          if (res.data.isEdit) {
            this.$set(operationData, "data", { id: value.id });
            this.$emit("edit", operationData);
          } else {
            this.$message.warning(
              // "抱歉，您无权编辑该条数据，请联系系统管理员。"
              this.$i18n.t("report_label_norole")
            );
          }
        }
      });
    },
    // 点击节点如果有子集调接口在当前节点下追加子集
    handleNodeClick(data) {
      if (
        data?.num > 0 &&
        data?.children.length > 0 &&
        !data.children[0].label
      ) {
        CommonObjApi.getCampaignLevel({ recordId: data.id }).then((res) => {
          if (res.data) {
            let tree = {};
            data.children = [];
            res.data.map((item) => {
              if (item.num > 0) {
                tree = {
                  id: item.id,
                  label: item.name,
                  children: [{}],
                  num: item.num,
                  permission: item.permission,
                };
              } else {
                tree = {
                  id: item.id,
                  label: item.name,
                  num: 0,
                  permission: item.permission,
                };
              }
              data.children.push(tree);
            });
          }
        });
      }
    },
    // 获取市场活动层级接口，根据id每次返回当前id下的子集记录
    // num>0是对当前记录增加children，显示展开按钮
    getCampaignLevel() {
      CommonObjApi.getCampaignLevel({ recordId: this.recordId }).then((res) => {
        if (res?.data && res.data.length > 0) {
          let tree = {};
          res.data.map((item) => {
            if (item.num > 0) {
              tree = {
                id: item.id,
                label: item.name,
                children: [{}],
                num: item.num,
                permission: item.permission,
              };
            } else {
              tree = {
                id: item.id,
                label: item.name,
                num: 0,
                permission: item.permission,
              };
            }

            this.structureData[0].num = res.data.length;
            this.structureData[0].children.push(tree);
          });
        }
      });
    },
    // 根据是否是服务控制台返回相应的详情页path
    getLinkDetail(data) {
      // 导航样式为控制台样式点击添加二级菜单
      if (this.$store.state.navigationStyle && data.permission == "true") {
        let menuObj = {
          name: data.label, // tab名称
          id: data.id, //id，唯一标识tab的
          routerName: "consoleDetail", //路由的名字
          params: {
            id: data.id,
            oprateType: "DETAIL",
          }, // 页面需要的参数
        };
        window.$CCDK.CCMenu.addMenu2(menuObj);
        this.$router.push({
          path: `/commonObjects/console-multi-screen/console-detail/${data.id}/DETAIL?dataId=${data.id}`,
        });
      } else if (data.permission == "true") {
        this.$router.push({
          path: `/commonObjects/detail/${data.id}/DETAIL`,
        });
      }
    },
  },
  watch: {
    dataTree: {
      handler(val) {
        this.structureData = [
          {
            id: val.recordId,
            label: val.recordName,
            children: [],
            permission: "true",
          },
        ];
        this.getCampaignLevel();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang='scss' scoped>
.content {
  width: 100%;
  display: flex;
  border: 1px solid #e1e1e1;
  .tree-box {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .link-name {
      color: #006dcc;
      cursor: pointer;
    }
    .no-permission {
      color: #080707;
      cursor: pointer;
    }
  }
  .edit-icon {
    padding: 0 35px;
  }
  .tree {
    width: 100%;
  }
}

::v-deep .el-tree-node__content {
  border-bottom: 1px solid #e1e1e1;
  height: 36px;
  line-height: 36px;
}
::v-deep .el-tree-node__expand-icon {
  font-size: 18px;
}
</style>
<style scoped>
.pop_small ul {
  margin: 0 -12px;
  min-height: 26px;
}

.pop_small li {
  min-height: 26px;
  padding-right: 10px;
  font-size: 12px;
  line-height: 26px;
  cursor: pointer;
  color: #3e3e3c;
}
.pop_small li span {
  padding-left: 10px;
}
.pop_small li:hover {
  background: #f3f2f2;
  color: #006dcc;
}
.cannotEdit {
  box-sizing: border-box;
  padding-right: 15px;
}
</style>
