<template>
  <div
    v-if="scriptLoaded && dropboxChooserIsSupported && buttonType == 'chooser'"
    @click="dropboxIconClicked"
  >
    <slot />
    <button v-if="!this.$slots.default">Open dropbox picker</button>
  </div>
  <div
    v-else-if="
      scriptLoaded && dropboxChooserIsSupported && buttonType == 'saver'
    "
    @click="dropboxSaverIconClicked"
    id="container-db"
  ></div>
</template>

<script>
export default {
  props: {
    apiKey: {
      type: String,
      required: true,
    },
    linkType: {
      type: String,
      default: 'preview',
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    extensions: {
      type: Array,
      default: function() {
        return []
      },
    },
    folderselect: {
      type: Boolean,
      default: false,
    },
    sizeLimit: {
      type: Number,
      default: 0,
    },
    buttonType: {
      type: String,
      default: 'chooser',
    },
    uploadFiles: {
      type: Array,
      default: function() {
        return []
      },
    },
  },
  data: () => ({
    scriptLoaded: false,
    dropboxChooserIsSupported: false,
    saverRendered: false,
  }),
  mounted() {
    this.$watch(() => {
      let _this = this
      if (this.scriptLoaded && this.uploadFiles.length && !this.saverRendered) {
        let options = {
          files: this.uploadFiles,
          success: function() {
            _this.$emit('saved')
          },
          cancel: function() {
            _this.$emit('cancel')
          },
          progress: function(progress) {
            _this.$emit('progress', { progress: progress })
          },
          error: function(errorMessage) {
            _this.$emit('error', { errorMessage: errorMessage })
          },
        }

        var button = window.Dropbox.createSaveButton(options)
        document.getElementById('container-db').appendChild(button)
        this.saverRendered = true
      }
    })
    if (window.Dropbox) {
      this.scriptLoaded = true
      this.dropboxChooserIsSupported = window.Dropbox.isBrowserSupported()

      if (!this.dropboxChooserIsSupported) {
        
      }
    } else {
      const dropBoxScript = document.createElement('script')
      dropBoxScript.onload = () => {
        this.scriptLoaded = true
        this.dropboxChooserIsSupported = window.Dropbox.isBrowserSupported()

        if (!this.dropboxChooserIsSupported) {
          
        }
      }
      dropBoxScript.setAttribute(
        'src',
        'https://www.dropbox.com/static/api/2/dropins.js'
      )
      dropBoxScript.setAttribute('id', 'dropboxjs')
      dropBoxScript.setAttribute('data-app-key', this.apiKey)
      document.head.appendChild(dropBoxScript)
      this.$store.commit('setDropbox', true)
    }
  },
  methods: {
    dropboxIconClicked() {
      let _this = this
      let options = {
        success: async (files) => {
          let attachments = []
          for (let i = 0; i < files.length; i++) {
            let attachment = {}
            attachment._id = files[i].id
            attachment.title = files[i].name
            attachment.size = files[i].bytes
            attachment.iconURL = files[i].icon
            attachment.link = files[i].link
            attachment.extension = `. ${files[i].name.split('.')[1]}`
            attachments.push(attachment)
          }
          this.tempAttachments = attachments
          _this.$emit('picked', this.tempAttachments)
        },

        cancel: function() {
          _this.$emit('cancel')
        },

        linkType: this.linkType,
        multiselect: this.multiselect,
        folderselect: this.folderselect,
      }
      if (this.extensions.length) {
        options.extensions = this.extensions
      }

      if (this.sizeLimit) {
        options.sizeLimit = this.sizeLimit
      }
      window.Dropbox.choose(options)
    },
    dropboxSaverIconClicked() {
      let _this = this
      let options = {
        files: this.uploadFiles,
        success: function() {
          _this.$emit('saved')
        },
        cancel: function() {
          _this.$emit('cancel')
        },
        progress: function(progress) {
          _this.$emit('progress', { progress: progress })
        },
        error: function(errorMessage) {
          _this.$emit('error', { errorMessage: errorMessage })
        },
      }
      window.Dropbox.save(options)
    },
  },
}
</script>

<style></style>
