<template>
  <!-- 开发人员：王雪茹 -->
  <div class="susp_box">
    <div class="object_box">
      <div class="pull-left topContent tit_box">
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          style="margin: 10px 10px 6px 10px"
        >
          <el-breadcrumb-item @click.native="goBigUrl" class="one_box">{{
            suspenList.recordObjName
          }}</el-breadcrumb-item>
          <el-breadcrumb-item @click.native="goUrl" class="two_box">{{
            suspenList.recordName
          }}</el-breadcrumb-item>
        </el-breadcrumb>
        <div
          style="margin-left: 10px; color: #222222"
          v-html="suspenList.objLabel"
        >
          <!-- {{ suspenList.objLabel }} -->
        </div>
      </div>
      <div class="pull-left topContent" style="width: 100%">
        <div class="pull-left left-content">
          <!-- 列表表头说明文字 -->
          <statistics
            :total="suspenList.totalCount"
            :isQueryCount="isQueryCount"
            :field="sortFieldName"
            :time-interval="timeInterval"
            :show-checked-length="false"
            :checked-length="
              ($refs.tablePanel && $refs.tablePanel.checkedList.length) ||
              lengths
            "
            @refresh="refresh"
          ></statistics>
        </div>

        <div
          class="pull-right right-content"
          style="padding-top: 0; margin-top: -10px; margin-bottom: 10px"
        >
          <!-- suspenList.showButton {{suspenList.showButton}} -->
          <el-button-group
            v-if="suspenList.showButton"
            style="position: relative; float: right"
            class="right_btn"
          >
            <el-button
              class="button"
              size="small"
              v-for="(item, i) in suspenList.showButton.slice(0, 5)"
              :key="i"
              @click="buttonFun(item, suspenList)"
              :style="{
                backgroundColor:
                  noFile &&
                  suspenList.objid === 'attachement' &&
                  item.id !== 'd01'
                    ? '#fff'
                    : '',
                color:
                  noFile &&
                  suspenList.objid === 'attachement' &&
                  item.id !== 'd01'
                    ? '#C0C4CC'
                    : '',
              }"
              :class="item.id === 'dropbox' ? 'dropbox' : ''"
            >
              <span v-if="item.id === 'dropbox' ? true : false">
                <svg
                  v-show="!noFile"
                  class="icon"
                  aria-hidden="true"
                  style="
                    width: 18px;
                    height: 18px;
                    position: absolute;
                    left: 9px;
                    top: 5px;
                  "
                  @click="dropboxPicker"
                >
                  <use href="#icon-dropbox"></use>
                </svg>
                <svg
                  v-show="noFile"
                  class="icon"
                  aria-hidden="true"
                  style="
                    width: 18px;
                    height: 18px;
                    position: absolute;
                    left: 9px;
                    top: 5px;
                  "
                  @click="dropboxPicker"
                >
                  <use href="#icon-dropboxDisable"></use>
                </svg>
                <VueDropboxPicker
                  v-if="dropbox"
                  :api-key="dropboxApiKey"
                  link-type="preview"
                  :multiselect="true"
                  :folderselect="true"
                  :extensions="[]"
                  @cancel="onCancel"
                  @picked="onPicked"
                >
                  <svg
                    class="icon"
                    aria-hidden="true"
                    style="
                      width: 18px;
                      height: 18px;
                      position: absolute;
                      left: 9px;
                      top: 5px;
                    "
                  >
                    <use href="#icon-dropbox"></use>
                  </svg>
                </VueDropboxPicker>
              </span>

              <span v-else>{{ item.label }}</span>
            </el-button>
            <el-popover
              placement="bottom-end"
              popper-class="pop_box"
              :visible-arrow="false"
              trigger="hover"
            >
              <el-button
                class="list_xiala_s"
                size="small"
                slot="reference"
                v-show="suspenList.showButton.length > 5"
              >
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-sj"></use>
                </svg>
              </el-button>
              <ul class="moreButton">
                <li
                  v-for="(item, index) in suspenList.showButton.slice(5)"
                  :key="index"
                  style="cursor: pointer"
                  @click="buttonFun(item, suspenList)"
                >
                  {{ item.label }}
                </li>
              </ul>
            </el-popover>
          </el-button-group>
        </div>
      </div>
      <!--市场活动层次结构 -->
      <structureTree
        v-if="suspenList.objLabel == $t('c431') && relatedlistType === 'custom'"
        :dataTree="suspenList"
        :recordId="objId"
        @edit="edit"
        @del="del"
      >
      </structureTree>
      <!-- 列表 -->
      <table-panel
        :islineEdit="false"
        class="text-center"
        v-show="
          suspenList.objLabel != $t('c431') &&
          !(relatedlistType === 'cloudcc_k_article')
        "
        ref="tablePanel"
        :border="true"
        :table-height="tableHeight"
        :show-index="true"
        :checked="suspenList.objid === 'attachement' ? true : false"
        :showLock="false"
        :prefix="suspenList.prefix"
        :obj-id="suspenList.objid"
        :objectApi="suspenList.objName"
        :view-id="viewId"
        :view-modify="ismodifyFalg"
        :table-attr="suspenList.fieldList"
        :page-obj="pageObj"
        :table-button="tableBtnList"
        :noDataTip="noDataTip"
        :pict-loading="pictLoading"
        :mainId="objId"
        :itemObj="suspenList"
        @changeSortFiled="changeSortFiled"
        @wordWrap="wordWrap"
        @edit="edit"
        @del="del"
        @transferOwner="transferOwner"
        @transferClientOwner="transferClientOwner"
        @handleChange="handleChange"
        @onchange="onchange"
        @refresh="refresh"
        @invitationCode="invitationCodeFun"
        @qrCode="qrCodeFun"
        @activeMember="activeMemberFun"
      >
      </table-panel>
      <!-- 分页器 -->
      <div class="pagination-box">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[10, 25, 50, 100, 200]"
          :page-size="pageSize"
          :total="totalSize"
          layout="sizes, prev, pager, next"
        >
        </el-pagination>
      </div>
      <!-- 列表 -->
      <knowledgeindextable-panel
        class="text-centerKnow"
        ref="knowledgeindextablePanel"
        v-if="relatedlistType === 'cloudcc_k_article'"
        :border="true"
        :show-index="true"
        :showLock="false"
        :customHeader="false"
        :prefix="suspenList.prefix"
        :obj-id="suspenList.objid"
        :objectApi="suspenList.objName"
        :pageNum="pageNum"
        :table-attr="suspenList.fieldList"
        :page-obj="pageObj"
        :table-button="tableBtnList"
        :noDataTip="noDataTip"
        :pict-loading="pictLoading"
        :view-modify="ismodifyFalg"
        @edit="edit"
        @Additionalclose="Additionalclose"
        :caseKnowList="true"
        @dialogAssembly="dialogAssembly"
      ></knowledgeindextable-panel>
      <!-- 删除文章、删除草稿、归档、还原、作为草稿编辑 -->
      <Dialog
        :dialogSign="dialogSign"
        :btnName="btnName"
        :titleType="titleType"
        @dialogEdit="dialogEdit"
        @dialoghandleClose="dialoghandleClose"
      >
        <template v-slot:Dialog>
          <div>
            <div v-if="btnSign == 'draftdel'">
              <!-- "您无法恢复已删除的草稿。"
           "现有草稿翻译将链接到主语言文章的上一个发布版本。如果没有发布版本，将删除任何译稿。" -->
              <div class="dialog-type">
                {{ $t("label.knowledgebase.unable.restore.draft") }}
              </div>
              <div>
                {{
                  $t(
                    "label.knowledgebase.delete.translation.associated.with.draft"
                  )
                }}
              </div>
            </div>
            <div v-else-if="btnSign == 'articledel'" class="dialog-type">
              <!-- "删除已归档文章会将其和所有关联版本从知识库中永久移除。" -->
              <div>
                {{ $t("label.knowledgebase.permanently.remove.articles") }}
              </div>
            </div>
            <div v-else-if="btnSign == 'file'" class="dialog-type">
              <!-- "已发布翻译也将归档，将删除处于草稿状态的翻译。" -->
              <div>{{ $t("label.knowledgebase.published.translations") }}</div>
            </div>
            <div v-else-if="btnSign == 'recovery'" class="dialog-type">
              <!-- "还原会从此版本创建草稿。您将需要发布草稿，以使其可用。" -->
              <div>{{ $t("label.knowledgebase.restore.creates.draft") }}</div>
            </div>
            <div v-else-if="btnSign == 'draftedit'" class="dialog-type">
              <!-- "已发布版本仍将在线，直到您发布此草稿。" -->
              <div>
                {{ $t("label.knowledgebase.published.version.online") }}
              </div>
            </div>
          </div>
        </template>
      </Dialog>
      <!-- 删除数据提示 -->
      <el-dialog
        :visible.sync="dialogVisible"
        title="删除"
        top="15%"
        width="25%"
        :close-on-click-modal="false"
        :before-close="handleClose"
      >
        <!-- 确认删除该条数据吗 -->
        <span style="font-size: 14px; color: #080707">{{
          $t("label.weixin.confirm.delete")
        }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false" size="mini">
            <!-- 取消 -->
            {{ $t("label.ems.cancel") }}</el-button
          >
          <el-button @click="confirmDel" type="primary" size="mini">
            <!-- 删除 -->
            {{ $t("component_setup_tabs_label_delete") }}</el-button
          >
        </span>
      </el-dialog>

      <!-- 更改所有人 -->
      <transfer-owner-dialog
        ref="transferClientOwner"
        :objId="objId"
        :transfer-deault="transferDeault"
        :owner-type-list="ownerTypeList"
        :owner-raletion-list="ownerRaletionList"
        :transfer-client-owner-dialog="transferClientOwnerDialog"
        @closeTransferOwnerDialog="closeTransferOwnerDialog"
        @saveTransferOwner="saveTransferOwner"
      >
      </transfer-owner-dialog>

      <!-- 查找/查找多选 -->
      <!-- 搜索 -->
      <el-dialog
        :title="$t('label.quickbooks.searchs')"
        custom-class="dialog-form-wrapper"
        width="60%"
        top="5%"
        style="height: 91%"
        :visible.sync="showSearchTable"
        :lock-scroll="true"
        :close-on-click-modal="false"
        :modal="true"
        append-to-body
      >
        <search-table
          ref="searchTable"
          :fieldId="fieldId"
          :checked="checked"
          :relevant-objid="relevantObjId"
          :relevant-prefix="relevantPrefix"
          @changeSelect="changeSelect"
        />
      </el-dialog>
      <!-- 编辑记录 -->
      <create-edit-obj
        ref="editObj"
        :prefix="objPrefix"
        :id="objMainId"
        :object-api="objApi"
        :object-name="objMainName"
      ></create-edit-obj>
      <!-- 新增/修改记录 -->
      <create-edit-obj
        ref="createEditObj"
        :prefix="prefix"
        :id="id"
        :objectApi="objectApi"
        :relation-field-id="suspenList.fieldId"
        :object-name="suspenList.objLabel"
        type="active"
        :relative-record-id="objId"
        :realObjId="suspenList.objid"
        @save="save"
      >
      </create-edit-obj>
      <!--批量新增-->
      <el-dialog
        :visible.sync="batchAddDialog"
        top="10%"
        :title="batchAddDialogTitle"
        width="90%"
        :before-close="closeBatchAddDialog"
      >
        <batchAdd
          ref="batchAdd"
          :prefix="listPrefix"
          :objectApi="listObjectApi"
          :recordId="suspenList.recordId"
          :relation-field-id="suspenList.fieldId"
          :relatedListFlag="relatedListFlag"
          @getBatchLoading="getBatchLoading"
          @closeBatchAddDialog="closeBatchAddDialog"
        />
        <div slot="footer" class="dialog-footer" style="text-align: right">
          <el-button size="mini" @click="closeBatchAddDialog">
            <!-- 取消 -->
            {{ $t("label.cancel") }}
          </el-button>
          <el-button
            size="mini"
            type="primary"
            @click="saveBatchAdd"
            :loading="isBatchLoading"
          >
            <!-- 确定 -->
            {{ $t("label.confirm") }}
          </el-button>
        </div>
      </el-dialog>
      <!-- 业务机会产品、联系人角色、业务机会小组弹窗 -->
      <pop-upteam
        :popTableVisible="popTableVisible"
        :manualVisible="manualVisible"
        :addmanualVisible="addmanualVisible"
        :opportunityId="opportunityId"
        :productsVisible="productsVisible"
        :contactsVisible="contactsVisible"
        :noDataTip="popNoDataTip"
        :addContact="addContact"
        :relatedListObj="suspenList"
        :DeletName="DeletName"
        :prefix="prefix"
        @successFun="successFun"
        @popTableClose="popTableClose"
        @manualClose="manualClose"
        @addmanualClose="addmanualClose"
        @contactsClose="contactsClose"
        @addmanualChange="addmanualChange"
        @productsChange="productsChange"
        @addContactClose="addContactClose"
        @contactsVisibleOpen="contactsVisibleOpen"
        ref="popUpteam"
      />
      <!--报价单相关的弹窗-->
      <Quotation
        :addmanualVisible="singleAddmanualVisible"
        :manualVisible="singleManualVisible"
        :productsVisible="singleProductsVisible"
        :prefix="prefix"
        @successFun="singleSuccessFun"
        @manualClose="singleManualClose"
        @addmanualClose="singleAddmanualClose"
        @addmanualChange="singleAddmanualChange"
        @productsChange="singleProductsChange"
        @append="singleAppend"
        ref="quotation"
      />
      <!-- 文件列表弹窗 -->
      <add-files
        ref="atoLib"
        :isFromFileCenter="false"
        :dialogAddVisible="dialogAddVisible"
        @addSubmit="addWorkSubmit"
        @close="addWorkClose"
      ></add-files>
      <file-preview
        :show="showPreview"
        :showUrl="previewUrl"
        :showData="showPreviewData"
        :fromList="true"
        @closePreview="closePreview"
        ref="previewFile"
      >
        <!-- @closePreview="closePreview" -->
        <!-- @handleFile="handleFileClick" -->
      </file-preview>
    </div>
    <!-- 添加市场活动成员 -->
    <add-campaign-members
      :show-add-campaign-members="showAddCampaignMembers"
      :big-title="suspenList.objLabel"
      @closeAddCampaignMembersDialog="closeAddCampaignMembersDialog"
    >
    </add-campaign-members>
    <!-- 类似业务机会弹窗 -->
    <opportunities ref="opportunities"></opportunities>
    <!-- 批量发送邮件 -->
    <batch-send-email></batch-send-email>
    <!--表单营销推广新建编辑-->
    <formMarketingPromotion
      ref="formMarketingPromotion"
      :id="objId"
      :listId="listId"
      :relation-field-id="dialogNewAttr.id"
      @close="closeFormMarketingPromotion"
    >
    </formMarketingPromotion>
    <!-- （表单营销推广）邀约码 -->
    <invitationCode
      :id="rowId"
      :invitationCode="invitationCode"
      @closeInvitationCodeDialog="closeInvitationCodeDialog"
      @closeSendMail="closeSendMail"
    ></invitationCode>
    <!-- （表单营销推广）二维码 -->
    <qrcode ref="qrcode" :qrCodeId="rowId" :qrCodeName="qrCodeName"></qrcode>
  </div>
  <!-- </div> -->
</template>

<script>
import { Loading } from "element-ui";
import * as Emailobject from "./api.js";
import {
  Statistics,
  TransferOwnerDialog,
  TablePanel,
  knowledgeindextablePanel,
  Dialog,
} from "@/components/index";
import TransferOwnerMixin from "@/mixin/transferOwner.js";
import batchAdd from "@/components/ButtonWrapper/batchAdd";
import createEditObj from "./components/create-edit-obj.vue";
import createButton from "@/utils/Button";
import * as CommonObjApi from "./api";
import { GetDetailRelatedItems } from "./api";
import PopUpteam from "@/views/commonObjects/detailChild/Group/popUpTeam.vue";
import { Bind, deleteContact } from "./api";
import Quotation from "@/views/commonObjects/detailChild/Group/quotation.vue";
import AddFiles from "@/components/AddFiles/AddFiles";
import filePreview from "@/components/FileView/filePreview";
import addCampaignMembers from "./components/add-campaign-members.vue";
import opportunities from "@/components/Opportunities/index";
import batchSendEmail from "@/views/Email/batchSendEmail.vue";
import VueDropboxPicker from "@/components/vue-dropbox-chooser/DropboxPicker.vue";
import formMarketingPromotion from "@/views/commonObjects/formMarketingPromotion/index.vue";
import invitationCode from "@/views/commonObjects/formMarketingPromotion/invitationCode.vue";
import qrcode from "@/views/commonObjects/formMarketingPromotion/qrcode.vue";
import { cancelAttachCase } from "./api.js";
import DialogMixin from "@/mixin/Dialog.js";
import * as FormMarkObjApi from "@/views/commonObjects/formMarketingPromotion/api.js";
import structureTree from "@/views/commonObjects/components/structureTree.vue";
import caseHeardList from "@/views/commonObjects/fieldHeard.js";
export default {
  mixins: [TransferOwnerMixin, DialogMixin],
  components: {
    TablePanel,
    Statistics,
    createEditObj,
    PopUpteam,
    TransferOwnerDialog,
    Quotation,
    AddFiles,
    filePreview,
    addCampaignMembers,
    opportunities,
    batchSendEmail,
    batchAdd,
    VueDropboxPicker,
    knowledgeindextablePanel,
    Dialog,
    formMarketingPromotion,
    invitationCode,
    qrcode,
    structureTree
  },
  data() {
    return {
      dialogNewAttr: {
        id: "",
      },
      listObjectApi: "",
      listPrefix: "",
      recordType: "",
      //管理小组
      popTableVisible: false,
      //选择价格手册
      manualVisible: false,
      //添加产品
      addmanualVisible: false,
      //编辑全部
      productsVisible: false,
      //编辑业务机会联系人
      contactsVisible: false,
      opportunityId: "",
      //以下是报价单的添加产品及价格手册
      singleManualVisible: false,
      singleAddmanualVisible: false,
      singleProductsVisible: false,
      popNoDataTip: false, // 是否提示无更多数据
      //添加文件
      dialogAddVisible: false,
      //市场活动成员
      showAddCampaignMembers: false,
      flag: "",
      rowIndex: "",
      apiname: "",
      ind: 0,
      alldataists: [],
      recordId: "", //主从记录ID
      editableCellValue: { value: "" },
      ownerId: "",
      dataLists: [],
      showSearchTable: false,
      fieldId: "",
      relevantObjId: "",
      relevantPrefix: "",
      checkList: [],
      zhucongfield: [],
      fieldlist: [], //表头信息
      isaddShow: true,
      transferClientOwnerDialog: false,
      stateone: "",
      validRule: true,
      state: "",
      selectNum: 0,
      checked: false,
      optionList: {},
      pictLoading: false,
      printFlag: false,
      lengths: 0,
      viewIsmodify: false, // 当前视图是否可编辑
      url: "",
      objectApi: "", // 对象api
      objectApis: [], //主从objectApis
      relatedApinames: [], //主从relatedApiname
      objId: this.$route.params.id, // 对象id
      prefix: "", // 对象前缀
      tableSearchParams: {},
      inputWidth: "300px",
      tableHeight: "",
      tableAttr: null,
      tableBtnList: {
        // 操作
        title: this.$i18n.t("label_partner_all_operate"),
        buttonList: [
          createButton({
            label: this.$i18n.t("pagecreator_page_button_edit"), //"编辑",
            action: "edit",
          }),
          createButton({
            label: this.$i18n.t("component_setup_tabs_label_delete"), //"删除",
            action: "del",
          }),
        ],
        buttonListKnowone: [],
        buttonListKnowtwo: [],
        buttonListKnowthree: [],
        buttonListKnowfour: [],
      },
      drafteditdialogVisible: false,
      relatedlistType: this.$route.query.relatedlistType,
      pageObj: {
        dataList: [],
      },
      dialogVisible: false,
      showBtns: [],
      viewId: "",
      viewList: [], // 视图列表
      viewLength: 0,
      pageNum: 1, //页码默认为1
      pageSize: 50, // 每页展示数据条数
      totalSize: 0, // 总记录数
      // 滚动加载，是否无数据了
      noDataTip: false,
      sortField: "", // 排序字段
      sortFieldName: "",
      sortDir: "asc", // 排序方式
      viewSelectedFieldList: [],
      viewUnselectedFieldList: [],
      searchKeyWord: "", // 搜索关键字
      conditionValues: "",
      tagIds: [], // 搜索标签
      isAllTag: "", // 是否同时满足所有标签
      isallsearch: "false",
      myself: "false",
      scrollId: "false",
      getViewListDataParams: {},
      id: "", //记录id
      timer: "", //定义一个定时器的变量
      lastRefresh: new Date(), // 最后一次更新时间
      timeInterval: "",
      labelArr: [],
      str: "",
      isQueryCount: true,
      viewInfo: {},
      strs: [],
      relatedItem: {}, // 事件类型的相关项
      currentUserInfo: {}, // 当前登录用户
      eventDate: {}, // 事件类型的日期
      addable: "",
      reportList: [], // 对象对应的的报表列表
      listDashboard: [], // 对象下的仪表板列表
      itemArr: {},
      suspenList: {},
      dataList: [],
      infoId: "",
      workArr: [],
      showPreview: false,
      previewUrl: "",
      showPreviewData: {}, // 预览数据
      addContact: false,
      //批量新增
      batchAddDialog: false,
      isBatchLoading: false,
      batchAddDialogTitle: this.$i18n.t("label.object.batchadd", {
        objName: this.tabName,
      }),
      relatedListFlag: true,
      ismodifyFalg: "false",
      isDropboxState: null, //Dropbox功能是否开启
      dropboxApiKey: "aj2eb0wetk4aocr",
      isVueDropboxPicker: this.$store.state.VueDropboxPicker, //判断VueDropboxPicker组件是否加载完成
      DeletName: "",
      dropbox: false,
      noFile: false,
      objPrefix: "",
      objMainId: "",
      objApi: "",
      objMainName: "",
      invitationCode: false, //邀约码开关
      rowId: "", //邀约码每行id
      qrCodeName: "", //二维码每行名称
      listId: "", //相关列表id
      // 客户小组
      accountTeamList: {},
      // 当前第几页
      currentPage: 1,
    };
  },
  created() {
    this.objId = this.$route.params.id;
    this.prefix = this.$route.params.prefix;
  },
  mounted() {
    this.tableHeight =
      document.getElementsByClassName("home_content")[0].clientHeight - 210;
    this.getListTable();
    // });
    // 计时器计算多久前更新
    this.timer = setInterval(() => {
      // 获取当前时间
      let currentTime = new Date();
      let interval = currentTime - this.lastRefresh;

      //计算出相差天数
      let days = Math.floor(interval / (24 * 3600 * 1000));
      //计算出小时数
      let leave1 = interval % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      let hours = Math.floor(leave1 / (3600 * 1000));
      //计算相差分钟数
      let leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      let minutes = Math.floor(leave2 / (60 * 1000));
      //计算相差秒数
      let leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      let seconds = Math.round(leave3 / 1000);
      hours = minutes > 30 ? hours + 1 : hours;
      if (days > 0) {
        this.timeInterval =
          hours > 0
            ? `${days} ${this.$i18n.t(
                "label.login.access.day"
              )}${hours} ${this.$i18n.t("label_tabpage_hoursagoz")}`
            : `${days} ${this.$i18n.t("label_tabpage_Sdfez")}`;
      } else if (hours > 0) {
        this.timeInterval = `${hours} ${this.$i18n.t(
          "label_tabpage_hoursagoz"
        )}`;
      } else if (minutes > 0) {
        this.timeInterval = `${minutes} ${this.$i18n.t(
          "label_tabpage_minutesagoz"
        )}`;
      } else {
        this.timeInterval =
          seconds > 43
            ? this.$i18n.t("vue_label_commonobjects_view_secondsbefore", {
                seconds: seconds,
              })
            : this.$i18n.t("label.notification.time1");
      }
    }, 1000);
    this.getPermission();
  },
  methods: {
    /**
     * handleSizeChange：分页pageSize 每页条数改变
     * @param {number} val 改变后的每页展示的数据条数
    */
    handleSizeChange(val) {
      this.pictLoading = true;
      this.pageNum = 1;
      this.pageSize = val;
      // 滚动条回到顶部
      const doms = document.querySelectorAll(
        ".content-main >#table>.el-table__body-wrapper"
      );
      const dom = doms[doms.length - 1];
      dom.scrollTop  = 0 ;
      setTimeout(() => {
        this.pageObj.dataList = this.suspenList.data.slice(
        0,
        this.pageSize * this.pageNum
      );
        this.pictLoading = false;
      }, 1000);
    },
    /**
     * handleCurrentChange：currentPage 当前页改变时会触发
     * @param {number} val 改变后当前页数
     * @returns {}
    */
    handleCurrentChange(val) {
      this.pictLoading = true;
      this.pageNum = val;
      // 滚动条回到顶部
      const doms = document.querySelectorAll(
        ".content-main >#table>.el-table__body-wrapper"
      );
      const dom = doms[doms.length - 1];
      dom.scrollTop  = 0 ;
      setTimeout(() => {
        this.pageObj.dataList = this.suspenList.data.slice(
        this.pageSize * (this.pageNum - 1),
        this.pageSize * this.pageNum
      );
        this.pictLoading = false;
      }, 1000);
    },
    // 用户是否有文件操作权限
    getPermission() {
      // 某条记录锁定，且当前用户对这条记录没有编辑权限
      CommonObjApi.getPermissionById({
        id: this.objId,
      }).then((res) => {
        if (res.data.isLocked === true && res.data.isEdit === false) {
          this.noFile = true;
        }
      });
    },
    goBigUrl() {
      this.$router.go(-1);
    },
    goUrl() {
      this.$router.go(-1);
    },
    /**
     * 获取表格数据
     */
    getListTable() {
      this.pictLoading = true;
      if (this.$route.query.relatedlistType === "accountteam") {
        CommonObjApi.getRelateObjOption({}).then((result) => {
          this.accountTeamList = {};
          result.data?.objOptions?.forEach((item) => {
            this.accountTeamList[item.codevalue] = item.codetext;
          });
        });
      }
      let data = {
        recordId: this.$route.params.id,
        layoutId: this.$route.query.layoutId,
        relatedlistId: this.$route.query.relatedlistId,
        perPage: "5000",
        attachementType:
          this.$route.params.type !== "undefined"
            ? this.$route.params.type
            : "",
      };

      GetDetailRelatedItems(data).then((res) => {
        // 手动添加totalCount属性
        if (res.data && res.data.data) {
          if (res.data.data && res.data.data[0] && res.data.data[0].norecord) {
            res.data.totalCount = 0;
          } else {
            res.data.totalCount = res.data.data.length;
          }
        } else {
          res.data.totalCount = 0;
        }
        // "个案历史跟踪":表头数据在前端获取 res?.data?.relatedlistType
        if (res?.data?.relatedlistType === "casehis") {
          caseHeardList.forEach((item) => {
            item.label = this.$i18n.t(item.label);
            if (Array.isArray(item.options)) {
              item.options.forEach((opItem) => {
                opItem.codevalue = this.$i18n.t(opItem.codevalue);
              });
            }
          });
          res.data.fieldList = caseHeardList;
        }
        if (res.data.objid === "attachement") {
          this.isDropboxState = res.data.dropboxState;
        }
        if (
          res.data.objName === "opportunityteam" ||
          res.data.objName === "AccountTeam"
        ) {
          //业务机会小组
          //   客户小组
          res.data.showButton = [
            {
              id: "",
              label: this.$i18n.t("vue_label_commonobjects_detail_team"),
            },
          ];
        } else if (res.data.objName === "opportunitypdt") {
          //业务机会产品
          res.data.showButton = [
            {
              label: this.$i18n.t("lable.product.pricebooks.editall"), //编辑全部
              name: "editall",
            },
            // {
            //   label: this.$i18n.t("lable.opppdt.selPbk.title"), //选择价格手册
            //   name: "selectpricebooks",
            // },
            {
              label: this.$i18n.t("label.quote.choseproduct"), //"添加产品"
              name: "tjywjhcp",
            },
          ];
        } else if (res.data.objName === "campaign") {
          //市场活动历史
          res.data.showButton = [];
        } else if (res.data.objName === "quotedetail") {
          //报价单明细
          res.data.showButton = [
            {
              id: "",
              label: this.$i18n.t("label.quote.detailinfo.add"), //",
            },
          ];
        } else if (res.data.objName === "tp_sys_approval_instance_his") {
          res.data.showButton = [];
        } else if (res.data.objName === "campaignmember") {
          //市场活动成员
          res.data.showButton = [
            {
              id: "",
              label: this.$i18n.t("label.add"), //"添加市场活动成员",
            },
            {
              id: "",
              label: this.$i18n.t("label.emailobject.groupemail"), //群发邮件
            },
          ];
        } else if (res.data.objName === "pricebook") {
          //价格手册
          // res.data.showButton = [
          //   {
          //     id: "",
          //     //   添加标准价格
          //     label: this.$i18n.t("vue_label_pricebook_price"),
          //   },
          //   {
          //     id: "",
          //     label: "添加到价格手册",
          //   },
          // ];
        } else if (res.data.objName === "Attachement") {
          //文件
          //文件
          if (this.isDropboxState === "1") {
            res.data.showButton = [
              {
                id: "dropbox",
                label: "", //添加文件
              },
              {
                id: "",
                label: this.$i18n.t("label.file.library.selectFile2Lib"), //添加文件
              },
              {
                id: "d01",
                label: this.$i18n.t("chatter.down"), //"下载"
              },
            ];
          } else {
            res.data.showButton = [
              {
                id: "",
                label: this.$i18n.t("label.file.library.selectFile2Lib"), //添加文件
              },
              {
                id: "d01",
                label: this.$i18n.t("chatter.down"), //"下载"
              },
            ];
          }
        } else if (
          res.data.objName === "tp_sys_contactrole" &&
          this.$route.query.objId === "opportunity"
        ) {
          //联系人角色（只有在业务机会下有关系网）
          res.data.showButton = [
            // {
            //   id: "",
            //   label: this.$i18n.t("label.add"), //"添加",
            // },
            {
              id: "",
              label: this.$i18n.t("button_partner_workspace_manage"), //"管理",
            },
            // {
            //   id: "",
            //   label: this.$i18n.t("vue_label_commonobjects_detail_network"), //关系网
            // },
          ];
          this.tableBtnList.buttonList = [];
        } else if (
          res.data.objName === "Contact" &&
          this.$route.query.objId === "account"
        ) {
          //联系人（只有在客户下有关系网）
          res.data.showButton = [
            {
              id: "",
              label: this.$i18n.t("vue_label_commonobjects_detail_network"), //关系网
            },
          ];
          let addBtn = res.data.buttons.find((btn) => {
            return btn.name === "New";
          });
          let batchAddBtn = res.data.buttons.find((btn) => {
            return btn.name === "Batch Added";
          });
          addBtn &&
            res.data.showButton.push({
              id: "",
              label: this.$i18n.t("label.ems.create"), //"新建",
            });
          batchAddBtn &&
            res.data.showButton.push({
              id: "",
              label: this.$i18n.t("label.batchadd"), //"批量新增",
            });
        } else if (res.data.objName === "tp_sys_opportunity_bookmarks") {
          //类似业务机会
          res.data.showButton = [
            {
              id: "",
              label: this.$i18n.t("label_tabpage_findz"), //"查找"
            },
          ];
        } else if (
          res.data.objName === "track" ||
          res.data.objName === "Marketsea"
        ) {
          //字段跟踪历史和客户公海池字段跟踪
          res.data.showButton = [];
        } else if (res.data.objName === "blockprice") {
          //分块定价
          res.data.showButton = [
            {
              id: "",
              label: this.$i18n.t("label.batcheditor"), //"批量编辑",
            },
          ];
          let addBtn = res.data.buttons.find((btn) => {
            return btn.name === "New";
          });
          let batchAddBtn = res.data.buttons.find((btn) => {
            return btn.name === "Batch Added";
          });
          addBtn &&
            res.data.showButton.push({
              id: "",
              label: this.$i18n.t("label.ems.create"), //"新建",
            });
          batchAddBtn &&
            res.data.showButton.push({
              id: "",
              label: this.$i18n.t("label.batchadd"), //"批量新增",
            });
        } else if (res.data.objid === "cloudcc_k_article") {
          // 个案下文章列表
          this.tableBtnList.buttonListKnowone = [
            createButton({
              // 取消附加文章
              label: "取消附加文章",
              action: "Additionalclose",
            }),
            createButton({
              // 编辑
              label: this.$i18n.t("label.modify"),
              action: "edit",
            }),
          ];
          this.tableBtnList.buttonListKnowtwo = [
            createButton({
              // 取消附加文章
              label: "取消附加文章",
              action: "Additionalclose",
            }),
            createButton({
              // 作为草稿编辑
              label: "作为草稿编辑",
              action: "draftedit",
            }),
          ];
          this.tableBtnList.buttonListKnowthree = [
            createButton({
              // 取消附加文章
              label: "取消附加文章",
              action: "Additionalclose",
            }),
          ];
          this.tableBtnList.buttonListKnowfour = [
            createButton({
              // 取消附加文章
              label: "取消附加文章",
              action: "Additionalclose",
            }),
          ];
        } else if (
          (this.$route.query.state == "草稿" ||
            this.$route.query.state == "已归档") &&
          res.data.objid === "promoted_search_term"
        ) {
          //知识文章下搜索性能词列表
          this.tableBtnList.buttonList = [];
        } else if (res.data.objName === "campaignform") {
          res.data.showButton = [
            {
              id: "",
              label: this.$i18n.t("label.ems.create"), //"新建",
            },
          ];
        } else {
          res.data.showButton = [];
          let addBtn = res.data.buttons.find((btn) => {
            return btn.name === "New";
          });
          let batchAddBtn = res.data.buttons.find((btn) => {
            return btn.name === "Batch Added";
          });
          addBtn &&
            res.data.showButton.push({
              id: "",
              label: this.$i18n.t("label.ems.create"), //"新建",
            });
          batchAddBtn &&
            res.data.showButton.push({
              id: "",
              label: this.$i18n.t("label.batchadd"), //"批量新增",
            });
        }
        // 预计人工成本、实际人工成本不展示操作列
        if (
          res.data.objName === "cloudcc_real_labor_costs" ||
          res.data.objName === "cloudccPlanCost"
        ) {
          this.tableBtnList.buttonList = [];
        }
        // 表单营销推广(操作按钮 编辑、删除、邀约码、二维码、市场活动)
        if (
          res.data &&
          res.data.objLabel &&
          res.data.objLabel === this.$i18n.t("c11")
        ) {
          this.tableBtnList = {
            // 操作
            title: this.$i18n.t("label_partner_all_operate"),
            buttonList: [
              {
                action: "edit",
                label: this.$i18n.t("label.emailtocloudcc.edit"),
              },
              {
                action: "del",
                label: this.$i18n.t("pagecreator.page.button.delete"),
              },
              {
                action: "invitationCode",
                label: this.$i18n.t("label.yard"),
              },
              { action: "qrCode", label: this.$i18n.t("label.qrcode") },
              {
                action: "activeMember",
                label: this.$i18n.t("label.members.activity"),
              },
            ],
          };
        }

        // 自定义按钮
        res.data.buttons.forEach((btn) => {
          //0521先注掉客户下联系人架构图按钮
          if (
            btn.category === "CustomButton" &&
            btn.name !== "Architecture diagram"
          ) {
            res.data.showButton.push({
              id: btn.id,
              label: btn.label,
              category: btn.category,
              behavior: btn.behavior,
              event: btn.event,
              function_code: btn.function,
            });
          }
        });

        this.suspenList = res.data;
        // 如果是客户小组的话，特殊处理相关对象权限
        if (res.data.objName === "AccountTeam") {
          this.suspenList?.data.forEach((item) => {
            item.xglbqx = this.accountTeamList[item.xglbqx];
          });
        }
        this.objMainName = this.suspenList.recordObjName;
        this.prefix = this.suspenList.prefix;
        this.objectApi = this.suspenList.objName;
        this.suspenList.fieldList.forEach((item, idx) => {
          // 名称、查找、主详、查找多选均可跳转
          if (
            item.name === "name" ||
            item.type === "Y" ||
            item.type === "M" ||
            item.type === "MR" ||
            item.name === "subject" ||
            item.lookupObj === "user" ||
            item.name === "whoid" ||
            item.name === "relateid"
          ) {
            this.$set(this.suspenList.fieldList[idx], "click", "detail");
          }
          // 项目管理系统-详情-实际工作清单点击日期跳转详情
          if (sessionStorage.getItem("activeTabObjId") === "projectworklist") {
            if (item.type === "D") {
              this.$set(this.suspenList.fieldList[idx], "click", "detail");
            }
          }
          if (this.suspenList.type === "attachement" && this.noFile === false) {
            this.tableBtnList = {
              // 操作
              title: this.$i18n.t("label_partner_all_operate"),
              buttonList: [
                createButton({
                  label: this.$i18n.t("component_setup_tabs_label_delete"), //"删除",
                  action: "del",
                }),
              ],
            };
          }
          if (this.suspenList.type === "attachement" && this.noFile) {
            this.tableBtnList = {
              // 操作
              title: this.$i18n.t("label_partner_all_operate"),
              buttonList: [],
            };
          }
          //文件字段转换字段名称
          if (item.type === "FL") {
            this.suspenList.data.forEach((childVal) => {
              this.$set(
                childVal,
                item.name + "List",
                childVal[item.name + "ccfilelist"]
              );
            });
          }
          this.$set(this.suspenList.fieldList[idx], "sortByThis", false);
          this.$set(this.suspenList.fieldList[idx], "sortDir", "asc");
          this.$set(this.suspenList.fieldList[idx], "fixed", false);
          this.$set(this.suspenList.fieldList[idx], "locked", false);
          item.schemefieldType = item.type;
          item.schemefieldName = item.name;
          item.schemefieldLength = item.length;
          item.apiname = item.name;
          item.nameLabel = item.label;
        });
        this.totalSize = this.suspenList?.data?.length;
        this.pageObj.dataList = this.suspenList?.data?.slice(0, this.pageSize);
        this.$nextTick(() => {
          this.$refs.tablePanel &&
            this.$refs.tablePanel.changeTableAttr(this.suspenList.fieldList);
        });
        document.title = this.$setTitle(this.suspenList.objLabel);
        setTimeout(() => {
          this.pictLoading = false;
        }, 1000);
      });
    },
    // 刷新
    refresh() {
      this.sortField = "";
      this.sortFieldName = "";
      this.lastRefresh = new Date();
      // 重置列表信息
      this.pageObj.dataList = [];
      this.pageNum = 1;
      this.getListTable();
      // 重置列表选中统计数据
      this.$refs.tablePanel.checkedList = [];
    },
    // 搜索条件改变
    changeKeyWord(val) {
      this.pageNum = 1;
      this.searchKeyWord = val;
    },
    // 新建
    // add() {
    //   this.id = "";
    //   this.$refs.createEditObj.add();
    // },
    // 进入特殊新建编辑页
    goToEdit(objectApi) {
      this.$nextTick(() => {
        this.$refs.editObj.id = this.$route.params.id;
        this.$refs.editObj.objectApi = objectApi;
        this.$refs.editObj.objId = this.suspenList.recordObjId;
        this.$refs.editObj.edit();
      });
    },
    // 编辑
    edit(row) {
      if (this.suspenList.objName === "campaignform") {
        //表单营销推广
        this.$refs.formMarketingPromotion.dialogFormVisible = true;
        this.$nextTick(() => {
          this.$refs.formMarketingPromotion.edit();
        });
      } else {
        CommonObjApi.getPermissionById({ id: row.data.id }).then((res) => {
          if (res.data.isEdit) {
            if (
              this.suspenList.recordObjId === "20210525cloudccorder" &&
              this.suspenList.objName === "cloudccorderitem"
            ) {
              this.goToEdit("cloudccorder");
            } else if (
              this.suspenList.recordObjId === "invoices" &&
              this.suspenList.objName === "InvoicesItems"
            ) {
              this.goToEdit("Invoices");
            } else if (
              this.suspenList.recordObjId === "2021127purchaseorder" &&
              this.suspenList.objName === "purchaseorderitem"
            ) {
              this.goToEdit("purchaseorder");
            } else {
              this.id = row.data.id;
              this.$nextTick(() => {
                this.$refs.createEditObj.edit();
              });
            }
          } else {
            this.$message.warning(this.$i18n.t("report_label_norole")); //"抱歉，您无权编辑该条数据，请联系系统管理员。"
          }
        });
      }
    },
    // 转移所有人
    transferOwner(row) {
      this.id = row.data.id;
      this.stateone = "";
      this.initTransferOwner(this.objId, this.id);
      this.transferClientOwnerDialog = true;
    },
    // 更改客户所有人
    transferClientOwner(row) {
      this.id = row.data.id;
      this.stateone = "";
      this.initTransferOwner(this.objId, this.id);
      this.transferClientOwnerDialog = true;
    },
    saveTransferOwnerSuccess() {
      // 更改成功
      this.$message.success(this.$i18n.t("vue_label_notice_change_success")); //"修改记录类型成功"
      this.pageObj.dataList = [];
      this.pageNum = 1;
      this.closeTransferOwnerDialog();
    },
    closeTransferOwnerDialog() {
      this.transferClientOwnerDialog = false;
    },
    // 改变查找/查找带值选项及值
    changeSelect(row) {
      let options = [];
      let value = {
        value: row.data.id,
      };
      if (row instanceof Array) {
        row.forEach((ele) => {
          options.push({
            label: ele.name,
            value: ele.id,
          });
        });
      } else {
        options.push({
          label: row.data.name,
          value: row.data.id,
        });
      }
      this.isZhucongfield &&
        this.$set(
          this.dataLists[this.ind][this.rowIndex - 1],
          this.apiname,
          value
        );
      this.isZhucongfield &&
        this.$set(
          this.dataLists[this.ind][this.rowIndex - 1],
          `${this.apiname}Options`,
          options
        );
      this.showSearchTable = false;
      if (this.transferClientOwnerDialog) {
        this.changeSelectOwner(row);
      }
    },
    changeSelectOwner(row) {
      this.ownerId = row.data.id;
      this.showSearchTable = false;
    },
    querySearch(queryString, cb) {
      let restaurants = this.restaurants;
      let results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;

      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    // 新增/修改保存
    save() {
      this.getListTable();
    },
    // 删除
    del(row) {
      this.id = row.data.id;
      if (
        this.$store.state.userInfoObj.profileId === "aaa000001" ||
        row.data.attachtype ||
        this.objectApi === "campaignform"
      ) {
        this.dialogVisible = true;
      } else {
        CommonObjApi.getPermissionById({ id: this.id }).then((res) => {
          if (res.data.isDelete && !res.data.isLocked) {
            this.dialogVisible = true;
          } else {
            this.$message(
              this.$i18n.t("vue_label_commonobjects_detail_the_administrator")
            );
          }
        });
      }
    },
    // 确认删除数据
    confirmDel() {
      this.dialogVisible = false;
      if (this.objectApi === "tp_sys_contactrole") {
        deleteContact({
          id: this.id,
          objectApi: this.objectApi,
        }).then((res) => {
          if (res.result) {
            this.$message({
              showClose: true,
              type: "success",
              message: this.$i18n.t("label_tabpage_delsuccessz"),
            });
            this.pageNum = 1;
            this.getListTable();
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
      } else if (this.objectApi === "campaignform") {
        //表单营销推广
        FormMarkObjApi.deleteCampaignForm({ id: this.id }).then(() => {
          this.dialogVisible = false;
          this.$message({
            showClose: true,
            message: this.$i18n.t("label.ems.delete.success"),
            type: "success",
          }); //删除成功
          this.getListTable();
        });
      } else {
        CommonObjApi.del({
          id: this.id,
          objectApi: this.objectApi,
        }).then((res) => {
          if (res.result) {
            this.$message({
              showClose: true,
              type: "success",
              message: this.$i18n.t("label_tabpage_delsuccessz"),
            });
            this.pageNum = 1;
            this.getListTable();
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        });
      }
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false;
    },
    closePreview() {
      // 列表打开预览
      this.showPreview = false;
      this.previewUrl = "";
    },
    // 内联编辑
    handleChange() {},
    // 自动换行
    wordWrap() {},
    // 修改排序字段
    changeSortFiled(field) {
      this.pageNum = 1;
      this.pageObj.dataList = [];
      // 若排序字段不变，改变排序方式
      if (this.sortField === field.apiname) {
        this.sortDir = this.sortDir === "asc" ? "desc" : "asc";
        this.tableAttr.forEach((item) => {
          if (item.nameLabel === field.nameLabel) {
            item.sortDir = this.sortDir;
          }
        });
      } else {
        this.clearSort();
        this.tableAttr.forEach((item) => {
          if (item.nameLabel === field.nameLabel) {
            item.sortByThis = true;
            item.sortDir = "asc";
            this.sortDir = "asc";
            this.sortField = field.apiname;
            this.sortFieldName = item.nameLabel;
          }
        });
      }
      this.$forceUpdate();
      this.$refs.tablePanel.changeTableAttr(this.tableAttr);
      // 刷新视图
    },
    // 清除排序
    clearSort() {
      this.tableAttr.forEach((item) => {
        item.sortByThis = false;
      });
    },
    // 滚动加载数据
    // append () {
    //   if (!this.noDataTip) {
    //     this.getListTable();
    //   }
    // },
    onchange(arr) {
      this.workArr = arr;
      let ids = "";
      for (let i = 0; i < this.workArr.length; i++) {
        ids += this.workArr[i].id + ",";
      }
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (ids.length > 0) {
        ids = ids.substr(0, ids.length - 1);
      }
      this.str = ids;
    },
    chat(val) {
      this.str = val;
    },
    //点击按钮显示各自弹窗
    buttonFun(n, each) {
      this.DeletName = each.objName;
      this.listId = each.id;
      // 自定义按钮
      if (n.category === "CustomButton" && n.id !== "adc201300153342D9xq4") {
        // lightning版本自定义按钮 调用特定方法执行服务
        if (n.event === "lightning") {
          // 替换id
          if (n.function_code.indexOf("{!id}") !== -1) {
            n.function_code = n.function_code.replace("{!id}", this.id);
          }
          let param = JSON.parse(n.function_code);
          param.id = this.id;
          let loadingInstance = Loading.service(this.loadingOption);
          CommonObjApi.openCall(param).then((res) => {
            loadingInstance.close();
            if (res.data && res.data.action) {
              // 刷新页面
              if (res.data.action === "refresh") {
                this.$Bus.$emit("custombtnrefresh");
              } else if (res.data.action === "redirect") {
                // 重定向 需判断当前窗口打开还是新窗口打开
                if (res.data.mode === "new") {
                  window.open(res.data.url);
                } else if (res.data.mode === "now") {
                  window.location.href = res.data.url;
                }
              } else if (res.data.action === "alert") {
                // 不同类型的消息提示   wj
                this.$message({
                  message: res.data.message,
                  type: res.data.messageType || "info",
                });
              }
              //先弹窗，在当前页面跳转
              else if (res.data.action === "alert_refresh") {
                // 不同类型的消息提示   wj
                this.$message({
                  message: res.data.message,
                  type: res.data.messageType || "info",
                });
                setTimeout(() => {
                  this.$Bus.$emit("custombtnrefresh");
                  // this.refreshAll();
                }, 1000);
              }
              //先弹窗,在新标签页跳转
              else if (res.data.action === "alert_redirect") {
                // 不同类型的消息提示   wj
                this.$message({
                  message: res.data.message,
                  type: res.data.messageType || "info",
                });
                setTimeout(() => {
                  // 重定向 需判断当前窗口打开还是新窗口打开
                  if (res.data.mode === "new") {
                    window.open(res.data.url);
                  } else if (res.data.mode === "now") {
                    if (`/${window.location.hash}` === res.data.url) {
                      this.$Bus.$emit("custombtnrefresh");
                      // this.refreshAll();
                    } else {
                      window.location.href = res.data.url;
                    }
                  }
                }, 1000);
              }
            }
          });
        }
        // 自定义按钮lightning-script
        if (n.event === "lightning-script") {
          let funText = n.function_code ? n.function_code : n.functionCode;
          let newFun = Function(funText);
          newFun();
        }
        if (n.event === "lightning-url") {
          // 替换id
          if (n.function_code.indexOf("{!id}") !== -1) {
            n.function_code = n.function_code.replace("{!id}", this.id);
          }
          // 当前窗口打开
          if (n.behavior === "self") {
            // window.location.href = item.function_code
            if (n.function_code.substring(0, 7) == "cloudcc") {
              let searchUrl = n.function_code.split("?")[1];
              let id = n.function_code
                .split("?")[1]
                .split("&")[0]
                .split("=")[1];
              let page = n.function_code
                .split("?")[1]
                .split("&")[1]
                .split("=")[1];
              // 判断是否显示菜单栏
              let routeData = "";
              if (n.menubar == "show") {
                // 展示菜单栏
                routeData = this.$router.resolve({
                  path: "/injectionComponent",
                  query: { id: id, page: page, button: "Home" },
                });
              } else {
                // 不展示菜单栏
                routeData = this.$router.resolve({
                  path: "/injectionComponent1",
                  query: { id: id, page: page },
                });
              }
              if (n.function_code.indexOf("target") !== -1) {
                //在本页面打开一个新的窗口
                let paramsObj = this.$qs.parse(searchUrl); // 获取从？开始的url部分
                if (paramsObj.target.indexOf("dialog") !== -1) {
                  let obj = {
                    title: paramsObj.dialogTitle || "详情",
                    width: paramsObj.dialogWidth || "50%",
                    pageApi: page,
                  };
                  this.$bus.$emit("openDialog", obj);
                } else if (paramsObj.target.indexOf("window") !== -1) {
                  window.open(
                    routeData.href,
                    "newWindow",
                    "width=800,height=600,left=400,top=100,menubar=0,scrollbars=1, resizable=1,status=1,titlebar=0,toolbar=0,location=1"
                  );
                }
              } else {
                // 覆盖
                window.open(routeData.href, "_self");
              }
            } else {
              window.location.href = n.function_code;
            }
          } else {
            // 新窗口打开
            // window.open(item.function_code)
            // 新窗口打开
            if (n.function_code.substring(0, 7) == "cloudcc") {
              let searchUrl = n.function_code.split("?")[1];
              let paramsObj = this.$qs.parse(searchUrl);
              let id = n.function_code
                .split("?")[1]
                .split("&")[0]
                .split("=")[1];
              let page = n.function_code
                .split("?")[1]
                .split("&")[1]
                .split("=")[1];
              // 判断是否显示菜单栏
              let routeData = "";
              if (n.menubar == "show") {
                // 展示菜单栏
                routeData = this.$router.resolve({
                  path: "/injectionComponent",
                  query: {
                    id: id,
                    page: page,
                    button: "Home",
                    dialogTitle: paramsObj.dialogTitle,
                  },
                });
              } else {
                // 不展示菜单栏
                routeData = this.$router.resolve({
                  path: "/injectionComponent1",
                  query: {
                    id: id,
                    page: page,
                    dialogTitle: paramsObj.dialogTitle,
                  },
                });
              }
              window.open(routeData.href, "_blank");
            } else {
              window.open(n.function_code);
            }
          }
        } else if (
          n.event === "JavaScript" ||
          (n.event === "URL" && n.id !== "adc201300153342D9xq4")
        ) {
          this.$message.warning(this.$i18n.t("lable.Please.switch"));
          //
        }
      } else {
        if (
          each.objName === "opportunityteam" &&
          n.label === this.$i18n.t("vue_label_commonobjects_detail_team")
        ) {
          //"管理小组"
          this.popTableVisible = true;
          this.opportunityId = each.id;
          this.$refs.popUpteam.init();
        } else if (
          each.objName === "AccountTeam" &&
          n.label === this.$i18n.t("vue_label_commonobjects_detail_team")
        ) {
          //"客户 管理小组"
          this.popTableVisible = true;
          this.opportunityId = each.id;
          this.opportunityName = "管理小组";
          this.$refs.popUpteam.init();
        } else if (
          each.objName === "opportunitypdt" &&
          n.name === "selectpricebooks"
        ) {
          // '业务机会产品'
          this.manualVisible = true;
          this.$refs.popUpteam.manualInit();
        } else if (each.objName === "opportunitypdt" && n.name === "tjywjhcp") {
          this.$refs.popUpteam.isManual();
          // '业务机会产品'
        } else if (each.objName === "opportunitypdt" && n.name === "editall") {
          this.$refs.popUpteam.productsInit();
          this.productsVisible = true;
        } else if (
          each.objName === "quotedetail" &&
          n.label === this.$i18n.t("label.quote.detailinfo.add")
        ) {
          //"添加报价单明细"
          this.goToEdit("quote");
        }
        // else if (each.relatedlistType === 'approvalHistory' && n.label === "调回批准请求") {
        //   this.openApproval(1)
        // } else if (each.relatedlistType === 'approvalHistory' && n.label === "提交待审批") {
        //   this.openApproval(0)
        // } else if (each.relatedlistType === 'approvalHistory' && n.label === "进行审批") {
        //   this.openApproval(2)
        // } else if (each.relatedlistType === 'approvalHistory' && n.label === "重新分配") {
        //   this.openApproval(3)
        // }
        else if (
          each.objName === "campaignmember" &&
          n.label === this.$i18n.t("label.add")
        ) {
          //'添加市场活动成员'
          this.showAddCampaignMembers = true;
        } else if (n.label === this.$i18n.t("label.emailobject.groupemail")) {
          //群发邮件
          Emailobject.getEmailPageInfo({}).then((result) => {
            if (result.data && result.data.relatedActiveMailSetting == true) {
              if (each.data.length > 0) {
                let obj = JSON.parse(JSON.stringify(each.data));
                obj.forEach((res) => {
                  res.type = res.contact == null ? "lead" : "contact";
                });
                this.$Bus.$emit("deliver-market-activity", obj);
              } else {
                this.$Bus.$emit("deliver-zero-array", each.data);
              }
            } else {
              this.$router.push({ path: "/relation" });
            }
          });
        } else if (
          each.objName === "Attachement" &&
          n.label === this.$i18n.t("label.file.library.selectFile2Lib")
        ) {
          // 如果没有禁用
          if (this.noFile) {
            this.$message.warning(
              this.$i18n.t("front-lighting-module-v1-detail-file-lockInfo")
            );
          } else {
            //添加文件
            this.dialogAddVisible = true;

            this.$refs.atoLib.initPage();
          }
        } else if (
          each.objName === "Attachement" &&
          n.label === this.$i18n.t("chatter.down")
        ) {
          //"下载"
          this.attachBtn(each);
        } else if (n.label === this.$i18n.t("label.ems.create")) {
          // 报价单对象下的报价行新建
          if (
            each.recordObjId === "201100000006130OosPK" &&
            each.objid === "20210607thequoteline"
          ) {
            this.$router.push(`/quotation/editQuotation/${this.objId}`);
          } else if (
            each.recordObjId === "201100000006130OosPK" &&
            each.objid === "210609quotelinegroup"
          ) {
            this.$router.push(`/quotation/editQuotation/${this.objId}`);
          } else if (
            each.recordObjId === "invoices" &&
            each.objid === "invoicesitems"
          ) {
            this.goToEdit("Invoices");
          } else if (
            each.recordObjId === "20210525cloudccorder" &&
            each.objid === "2021cloudccorderitem"
          ) {
            this.goToEdit("cloudccorder");
          } else if (
            each.recordObjId === "2021127purchaseorder" &&
            each.objid === "2021purchasorderitem"
          ) {
            this.goToEdit("purchaseorder");
          } else if (
            //"新建"
            each.objName === "campaignform"
          ) {
            //表单营销推广
            this.$refs.formMarketingPromotion.dialogFormVisible = true;
            this.dialogNewAttr.id = "";
            this.$nextTick(() => {
              this.$refs.formMarketingPromotion.add();
            });
          } else {
            //"新建"
            this.recordType = "";
            this.id = "";
            this.$nextTick(() => {
              this.$refs.createEditObj.add();
            });
          }
        } else if (n.label === this.$i18n.t("label.batchadd")) {
          if (
            each.recordObjId === "201100000006130OosPK" &&
            each.objid === "20210607thequoteline"
          ) {
            this.$router.push(`/quotation/editQuotation/${this.objId}`);
          } else if (
            each.recordObjId === "201100000006130OosPK" &&
            each.objid === "210609quotelinegroup"
          ) {
            this.$router.push(`/quotation/editQuotation/${this.objId}`);
          } else if (
            each.recordObjId === "invoices" &&
            each.objid === "invoicesitems"
          ) {
            this.goToEdit("Invoices");
          } else if (
            each.recordObjId === "20210525cloudccorder" &&
            each.objid === "2021cloudccorderitem"
          ) {
            this.goToEdit("cloudccorder");
          } else if (
            each.recordObjId === "2021127purchaseorder" &&
            each.objid === "2021purchasorderitem"
          ) {
            this.goToEdit("purchaseorder");
          } else {
            //批量新增
            this.listPrefix = each.prefix;
            this.listObjectApi = each.objName;
            this.batchAddDialog = true;
            this.$nextTick(() => {
              this.$refs.batchAdd && this.$refs.batchAdd.init();
              this.$refs.batchAdd && this.$refs.batchAdd.getRecordType();
            });
            this.batchAddDialogTitle = this.$i18n.t("label.object.batchadd", {
              objName: each.objLabel,
            });
          }
        } else if (
          each.objName === "tp_sys_contactrole" &&
          n.label === this.$i18n.t("label.add")
        ) {
          this.addContact = true;
          this.$refs.popUpteam.addContactOpen();
          this.$refs.popUpteam.contactSelectArr = [];
        } else if (
          each.objName === "tp_sys_contactrole" &&
          n.label === this.$i18n.t("button_partner_workspace_manage")
        ) {
          //"管理"
          this.$refs.popUpteam.getInit();
          this.contactsVisible = true;
          // "关系网"
        } else if (
          each.objName === "tp_sys_contactrole" &&
          n.label === this.$i18n.t("vue_label_commonobjects_detail_network")
        ) {
          // 跳转到联系人架构图
          this.$router.push(
            `/structureChart/${this.objId}/${this.suspenList.recordName}/${this.$route.query.objId}`
          );
        } else if (
          each.objName === "Contact" &&
          n.label === this.$i18n.t("vue_label_commonobjects_detail_network")
        ) {
          // 客户 "关系网"  跳转到联系人架构图
          this.$router.push(
            `/structureChart/${this.objId}/${this.suspenList.recordName}/${this.$route.query.objId}`
          );
        } else if (
          each.objName === "tp_sys_opportunity_bookmarks" &&
          n.label === this.$i18n.t("label_tabpage_findz")
        ) {
          //"查找"
          this.$refs.opportunities.dialogVisibles();
          this.$refs.opportunities.each(each);
        } else if (
          each.objName === "blockprice" &&
          n.label === this.$i18n.t("label.batcheditor")
        ) {
          //"批量编辑"
          this.$router.push({
            path: `/quotation/blockPricing`,
            query: {
              id: this.objId,
            },
          });
        }
      }
    },
    attachBtn() {
      if (this.workArr.length > 0) {
        let excelList = [];
        this.workArr.forEach((val) => {
          if (val.id) {
            let baseURL = this.$baseConfig.baseURL;
            let token = this.$CCDK.CCToken.getToken();
            excelList.push(
              `${baseURL}/file/downloadAttachement?accessToken=${token}&id=${val.id}`
            );
          } else {
            this.$message({
              showClose: true,
              message: this.$i18n.t("label.unselectfile"),
              type: "warning",
            });
            return false;
          }
        });
        for (let i = 0; i < excelList.length; i++) {
          const iframe = document.createElement("iframe");
          iframe.style.display = "none"; // 防止影响页面
          iframe.style.height = 0; // 防止影响页面
          iframe.src = excelList[i];
          document.body.appendChild(iframe); // 这一行必须，iframe挂在到dom树上才会发请求
          // 5分钟之后删除
          setTimeout(() => {
            iframe.remove();
          }, 5 * 60 * 1000);
        }
      } else {
        this.$message({
          showClose: true,
          message: this.$i18n.t("label.unselectfile"),
          type: "warning",
        });
        return false;
      }
    },
    //编辑业务机会联系人
    contactsClose(n) {
      if (n) {
        this.getListTable();
      }
      this.contactsVisible = false;
      this.addContact = false;
    },
    //管理小组 关闭弹窗
    popTableClose(n) {
      if (n) {
        this.getListTable();
      }
      this.popTableVisible = false;
    },
    //选择价格手册 关闭弹窗
    manualClose() {
      this.manualVisible = false;
    },
    //添加产品 关闭弹窗
    addmanualClose() {
      this.addmanualVisible = false;
    },
    //是否刷新相关列表
    successFun(n) {
      if (n) {
        this.getListTable();
      }
      this.productsVisible = false;
    },
    //业务机会产品选择价格手册 下一步
    addmanualChange() {
      this.addmanualVisible = true;
      this.$refs.popUpteam.searchProducts();
      this.$refs.popUpteam.addmanualInit();
    },
    //添加产品 下一步
    productsChange() {
      this.productsVisible = true;
    },
    //报价单选择价格手册 下一步
    singleAddmanualChange() {
      this.singleAddmanualVisible = true;
      this.$refs.quotation.singleSearchProducts();
      this.$refs.quotation.addmanualInit();
    },
    // 滚动加载数据
    singleAppend() {
      this.singleSearchProducts();
    },
    //选择价格手册 关闭弹窗
    singleManualClose() {
      this.singleManualVisible = false;
    },
    //添加产品 关闭弹窗
    singleAddmanualClose() {
      this.singleAddmanualVisible = false;
    },
    //是否刷新相关列表
    singleSuccessFun(n) {
      if (n) {
        this.getListTable();
        this.singleProductsVisible = false;
      } else {
        this.singleProductsVisible = false;
      }
    },
    //添加产品 下一步
    singleProductsChange() {
      this.singleProductsVisible = true;
    },
    addWorkSubmit(checkedFileList) {
      let data = [];
      checkedFileList.map((item) => {
        let dataItem = {};
        dataItem.recordid = this.$route.params.id;
        dataItem.name = item.name.split(".")[0];
        dataItem.type = item.type;
        dataItem.fileContentId = item.fileContentId || item.file_info_id;
        dataItem.fileinfoid = item.id;
        data.push(dataItem);
      });
      Bind(JSON.stringify(data)).then((res) => {
        if (res.result === true) {
          this.$message({
            showClose: true,
            type: "success",
            message: this.$i18n.t("vue_label_notice_add_success"),
          });
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: this.$i18n.t("vue_label_notice_add_fail"), //"添加失败",
          });
        }
      });
      this.addWorkClose(true);
    },
    //添加文件
    addWorkClose(n) {
      if (n) {
        this.getListTable();
        this.dialogAddVisible = false;
      } else {
        this.dialogAddVisible = false;
      }
    },
    closeAddCampaignMembersDialog() {
      this.showAddCampaignMembers = false;
      this.getListTable();
    },
    addContactClose() {
      this.addContact = false;
    },
    contactsVisibleOpen() {
      this.contactsVisible = true;
    },
    //批量新增
    closeBatchAddDialog(isSave) {
      this.batchAddDialog = false;
      if (isSave === "save") {
        this.getListTable();
      }
    },
    //获取批量新增保存loading状态
    getBatchLoading(n) {
      this.isBatchLoading = n;
    },
    saveBatchAdd() {
      this.$refs.batchAdd.savedata();
    },
    onCancel() {},
    onPicked(files) {
      if (files.length > 1) {
        this.$message.info("最多只能选择一个");
      } else {
        let obj = {};
        files.forEach((file) => {
          let str1 = file.title.substring(0, file.title.indexOf("."));
          obj = {
            name: str1,
            recordId: this.id,
            url: file.link,
          };
          this.getSaveDropboxs(obj);
        });
      }
    },
    dropboxPicker() {
      // this.$message.info('请检查网络连接')
      this.dropbox = true;
    },
    async getSaveDropboxs() {
      // let res = await getSaveDropbox(params);
      // if (res.result) {
      //   this.getListTable();
      // }
    },
    drafteditdata() {
      this.$nextTick(() => {
        this.$refs.createEditObj.objId = "cloudcc_k_article";
        this.$refs.createEditObj.copy(this.id);
      });
    },
    // 取消附加文章
    Additionalclose(item) {
      let params = {
        articleId: item.data.id,
        caseId: this.objId,
      };
      cancelAttachCase(params).then(() => {
        this.$message.success(`文章 ${item.data.name}已取消附加到个案！`);
        this.getListTable();
      });
    },
    //邀约码
    invitationCodeFun(param) {
      this.rowId = param.data.id;
      this.invitationCode = true;
    },
    //关闭邀约码弹窗
    closeInvitationCodeDialog() {
      this.invitationCode = false;
    },
    //刷新详情页
    closeSendMail() {
      this.getListTable();
    },
    //二维码
    qrCodeFun(param) {
      this.rowId = param.data.id;
      this.qrCodeName = param.data.campaignidccname;
      this.$nextTick(() => {
        this.$refs.qrcode.qrcodeFlg = true;
        this.$refs.qrcode.init();
      });
    },
    //市场活动
    activeMemberFun(param) {
      this.$router.push({
        path: `/commonObjects/activeMember/${this.suspenList.recordId}`,
        query: {
          id: this.suspenList.recordId,
          channel: param.data.campaignidccname,
          tagId: param.data.id,
          layoutId: this.$route.query.layoutId,
          relatedlistId: this.suspenList.id,
        },
      });
    },
    closeFormMarketingPromotion() {
      this.getListTable();
    },
  },
  watch: {
    suspenList() {},
  },
  beforeDestroy() {
    localStorage.setItem("relevantObjectLevel", 0);
    // 在Vue实例销毁前，清除我们的定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style lang="scss" scoped>
.text-centerKnow {
  ::v-deep .el-popover__reference {
    margin-bottom: 0;
  }
}
::v-deep .el-button-group {
  position: relative;
  .moreButton {
    margin-bottom: 0;
    li {
      height: 30px;
      line-height: 30px;

      &:hover {
        color: #006dcc;
      }
    }
  }
  ::v-deep .button {
    height: 30px;
    text-align: center;
    padding: 0 15px;
    color: #006dcc;
    border: 1px solid #dedcda;
    &:hover {
      background: #fff;
      border: 1px solid #dedcda;
    }
  }
}
::v-deep .el-table--border {
  border-left: none;
  border-right: none;
  border-bottom: none;
}

::v-deep .el-table::before {
  height: 0;
}

::v-deep .el-table__fixed::before {
  height: 0;
}

.susp_box {
  border-left: none;
  border-right: none;
  border-radius: 3px;
  position: relative;
  height: 100%;
  padding: 10px 10px 10px 10px;
  .object_box {
    border-radius: 3px;
    border: 1px solid #dedcda;
    height: 100%;
    background: #f5f5f5;
    ::v-deep .el-breadcrumb__inner {
      cursor: pointer;
    }
  }
  .tit_box {
    width: 100%;
    // background: #fff;
    ::v-deep .one_box {
      .el-breadcrumb__inner {
        color: #006dcc;
        font-size: 12px;
        cursor: pointer;
      }
      :hover {
        color: #006dcc;
        font-size: 12px;
        cursor: pointer;
      }
    }
    ::v-deep .two_box {
      .el-breadcrumb__inner {
        color: #006dcc;
        font-size: 12px;
        cursor: pointer;
      }
      :hover {
        color: #006dcc;
        font-size: 12px;
        cursor: pointer;
      }
    }
  }
}

.left-content {
  width: 50%;
  padding: 10px 10px 0;
  .title {
    margin-bottom: 10px;
  }
}

.right-content {
  width: 50%;
  padding: 13px 10px 0 0;

  .searchWrapper {
    margin-right: 12px;
    height: 41px;
  }
}

.checkboxs {
  margin-left: 6px;
  font-size: 12px;
  line-height: 28px;
}
.spancolor {
  color: #fa6400;
}
.spans {
  height: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  padding-top: 12px;
}
.mtransferli {
  list-style: inside;
  height: 34px;
  font-size: 12px;

  font-weight: 400;
  color: #0c0b0b;
  line-height: 18px;
}

::v-deep .el-input {
  height: 30px;
  width: 100%;
  text-align: center;
}
::v-deep .el-input__inner {
  height: 30px;
  width: 100%;
}
::v-deep .el-button--mini {
  padding: 7px 7px;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: rgba(51, 51, 51, 1);
}
::v-deep .el-input__icon {
  height: auto;
}
.al {
  margin: 0 24px;
  width: auto;
  overflow: auto;
  max-height: 250px;
  border: 1px solid rgba(190, 225, 255, 1);
}
::v-deep .el-input__icon {
  margin-top: -4px;
}
ul {
  margin-bottom: 0;
}
.list_xiala_s {
  width: 32px;
  height: 32px;
  text-align: center;
  cursor: pointer;
  padding: 0;
}
.list_xiala_s svg {
  width: 16px;
}
.list_xiala_s:hover {
  background: #fff;
  border: 1px solid #dedcda;
}
.moreButton {
  margin: 0 -12px;
  min-height: 26px;
}
.moreButton li {
  min-height: 26px;
  font-size: 12px;
  padding: 0;
  text-align: center;
  line-height: 26px;
}

.moreButton li:hover {
  background: #f3f2f2;
  color: #006dcc;
}
::v-deep .dropbox {
  width: 38px;
  height: 32px;
}
::v-deep .tableHeadField {
  pointer-events: none !important;
  cursor: default !important;
}
.dialog-type {
  font-size: 16px;
  font-weight: bold;
  word-break: break-word;
}
.pagination-box {
  display: flex;
  .el-pagination {
    width: 100%;
    margin-right: 50px;
    margin-top: 20px;
    display: flex;
    align-content: flex-start;
    justify-content: flex-end;
  }
}
</style>
